import React, {useState, useEffect, useRef} from "react";
import Link from "next/link";
import queryString from "query-string";
import { useStore } from "../../context/state";
import { useRouter } from "../../hooks/useRouter";
import {
    Menu,
    MenuItem,
    MenuButton,
    useMenuState,
    ControlledMenu,
    MenuDivider,
    MenuHeader
} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import { dataSourceV2 } from "commons-ui/core/DataSource";
import "./SubMenu.module.scss";

const menu = [
  { name: "Home", link: "/" },
  { name: "Menu", link: "/menu" },
  { name: "Calendar", link: "/events" },
  { name: "Order Options", link: "/delivery" },
  { name: "Blog", link: "/blog" },
  { name: "About", link: "/about" },
  { name: "Contact Us", link: "/contacts" },
  { name: "Account", link: "/account", isPrivate: true },
  { name: "FAQs", link: "/faq"}
];

export const SubMenu = () => {
  const { ecommerceStoreId, dispatch, isMobile, apiPath, login } = useStore();
  const location = useRouter();
  const params = queryString.parse(location.search);
  const [deliveryList, setDeliveryList] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const isLogin = login.isLogin;
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const menu1 = useMenuState({ transition: true });
  const menu2 = useMenuState({ transition: true });
  const menu3 = useMenuState({ transition: true });
  const menu4 = useMenuState({ transition: true });
  const menu5 = useMenuState({ transition: true });


  const requestDeliveryStore = (parentId) => {
      dataSourceV2(`ecommerce_store_delivery_option_pickup_list?ecommerce_store_delivery_option_id=${parentId}`, {url: apiPath})
          .then(v=> {
              if (!v.message && v.item_list && v.item_list.length) {
                  setStoreList(v.item_list.filter(v => !["55169324-9bcc-4142-92e1-7e8d3c064014"].includes(v.ecommerce_store_delivery_option_pickup_id)));
              }
          });
  };

  const requestDelivery = () => {
      dataSourceV2(`ecommerce_store_delivery_option_list?ecommerce_store_id=${ecommerceStoreId}`, {url: apiPath})
          .then(v=> {
              if (!v.message && v.item_list && v.item_list.length) {
                  setDeliveryList(v.item_list.filter(v => !v.is_hidden));

                  v.item_list.map(item => {
                      if (item.type === "pick_up") {
                          requestDeliveryStore(item.ecommerce_store_delivery_option_id);
                      }
                  });
              }
          });
  };


  useEffect(() => {
      if (!isMobile) requestDelivery();
  }, []);

  if (isMobile) return <div style={{ height: 20 }}></div>;

  return (
      <div className="u-flexCenter u-justifyContentCenter">
          <nav className="sub-menu u-flex">
              <div ref={ref1} onMouseEnter={() => menu1.toggleMenu(true)}>
                  <MenuLink name="Order"/>
              </div>
              <ControlledMenu {...menu1}
                              anchorRef={ref1}
                              onMouseLeave={() => menu1.toggleMenu(false)}
                              onClose={() => menu1.toggleMenu(false)}
                  >
                  {
                      deliveryList.map((item, i) => {
                        let link = "/delivery/" + (item.slug || item["ecommerce_store_delivery_option_id"])
                        if (item.name && item.name.toLowerCase().includes("catering")) {
                            link = "/catering";
                        }
                          return (
                               <MenuItem>
                                   <MenuItemLink link={link} name={item.name}/>
                               </MenuItem>
                          );
                      })
                  }
                  <MenuItem>
                      <MenuItemLink link={"/gift-card-order"} name={<span className="u-flexCenter">Gift Card<CardGiftcardIcon fontSize="small" className="u-textColorNormal u-marginLeft5"/></span>}/>
                  </MenuItem>
              </ControlledMenu>


              <div ref={ref2} onMouseEnter={() => menu2.toggleMenu(true)}>
                  <MenuLink name="Visit" link="/stores"/>
              </div>
              <ControlledMenu {...menu2}
                              anchorRef={ref2}
                              onMouseLeave={() => menu2.toggleMenu(false)}
                              onClose={() => menu2.toggleMenu(false)}
                  >
                  {
                      storeList.map((item, i) => {
                          return (
                               <MenuItem>
                                   <MenuItemLink link={"/stores#" + (item.slug || item["ecommerce_store_delivery_option_pickup_id"])} name={item.name}/>
                               </MenuItem>
                          );
                      })
                  }
              </ControlledMenu>

              <MenuLink name="What's New" link="/blog"/>


              <div ref={ref3} onMouseEnter={() => menu3.toggleMenu(true)}>
                  <MenuLink link="/about" name="About"/>
              </div>
              <ControlledMenu {...menu3}
                                  anchorRef={ref3}
                                  onMouseLeave={() => menu3.toggleMenu(false)}
                                  onClose={() => menu3.toggleMenu(false)}
                      >
                      <MenuItem><MenuItemLink link="/about" name="Our Story"/></MenuItem>
                      <MenuItem ><MenuItemLink link="/jobs" name="Work With Us!"/></MenuItem>
                      <MenuDivider />
                      <MenuItem><MenuItemLink link="/faq" name="FAQ"/></MenuItem>
                      <MenuItem><MenuItemLink link="/policy-center" name="Policy Center"/></MenuItem>
                      <MenuItem ><MenuItemLink link="https://piroshkypiroshky.prowly.com/" name="Press | Media Kit"/></MenuItem>
              </ControlledMenu>

              <div ref={ref4} onMouseEnter={() => menu4.toggleMenu(true)}>
                  <MenuLink link="/contacts" name="Contact"/>
              </div>
              <ControlledMenu {...menu4}
                              anchorRef={ref4}
                              onMouseLeave={() => menu4.toggleMenu(false)}
                              onClose={() => menu4.toggleMenu(false)}
                  >
                  <MenuItem><MenuItemLink link="/contacts" name="Contact us"/></MenuItem>
                  <MenuItem onClick={() => dispatch(location.pathname + "?"+ queryString.stringify({...params, subscribe_form: true}))}><MenuItemLink name="Join our Newsletter"/></MenuItem>
              </ControlledMenu>
          </nav>
      </div>
  );
};

function MenuLink(props) {
  const activeClass = props.isActive ? " active" : "";
  const item = (
      <span
        className={
          "menu-item u-flexCenter" +
          activeClass +
          " " +
          (props.className || "")
        }
      >
          <strong>{props.name}</strong>
      </span>
  );
  if (!props.link) {
      return item;
  }
  return (
    <Link href={props.link}>
      <a>
        {item}
      </a>
    </Link>
  );
}

function MenuItemLink(props) {
    if (!props.link) {
        return <div>{props.name}</div>;
    }
  return (
    <Link href={props.link}>
      <a>
        {props.name}
      </a>
    </Link>
  );
}
